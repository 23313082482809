<template>
  <div>
    <Header></Header>
    <div class="register" id="content">
      <div class="login-img_bg" id="login_img"/>
      <div style="width:1140px;height:100%;margin:0 auto;position: relative;">
        <a-form :form="form" class="login-form" @submit="handleSubmit" id="login">
          <h3 class="title">修改密码</h3>
          <a-tabs default-active-key="1" @change="registerCodeCheck">
            <a-tab-pane key="1" tab="邮箱">
              <a-form-item>
                <a-input
                  size="large"
                  type="text"
                  placeholder="邮箱账号"
                  v-decorator="[
                              'email',
                              {rules: [{ required: true, message: '请输入邮箱！' },{type:'email', message:'邮箱地址格式错误！' }, { validator: handleUsernameOrEmail }], validateTrigger: 'change'}
                          ]"
                >
                  <img class="img1" :src="user" slot="prefix" alt=""/>
                </a-input>
              </a-form-item>
              <a-row :gutter="16">
                <a-col class="gutter-row" :span="14">
                  <a-form-item>
                    <a-input size="large" type="text" placeholder="验证码"
                             v-decorator="['captcha', {rules: [{ required: true, message: '请输入验证码！' }], validateTrigger: 'blur'}]"></a-input>
                  </a-form-item>
                </a-col>
                <a-col class="gutter-row" :span="10">
                  <a-button
                    class="getCaptcha"
                    tabindex="-1"
                    :disabled="state.smsSendBtn"
                    @click.stop.prevent="getCaptcha"
                    v-text="!state.smsSendBtn && '获取验证码' || (state.time+' s')"
                  ></a-button>
                </a-col>
              </a-row>
              <a-form-item has-feedback>
                <a-input
                  placeholder="输入密码"
                  v-decorator="[ 'password', { rules: [ { required: true, message: '请输入密码', }, { validator: validateToNextPassword}]}]"
                  type="password"
                  size="large"
                >
                  <img class="img2" :src="mima" slot="prefix" alt=""/>
                </a-input>
              </a-form-item>
              <a-form-item has-feedback>
                <a-input
                  placeholder="请确认密码"
                  size="large"
                  v-decorator="[ 'confirm', { rules: [ { required: true, message: '两次输入的密码不匹配',},{ validator: compareToFirstPassword }]}]"
                  type="password" @blur="handleConfirmBlur"
                >
                  <img class="img2" :src="mima" slot="prefix" alt=""/>
                </a-input>
              </a-form-item>
              <a-button
                class="submit"
                size="large"
                type="primary"
                htmlType="submit"
                :loading="state.loginBtn"
                :disabled="state.loginBtn"
              >提交
              </a-button>
              <div class="user-login"> 放弃修改，直接
                <router-link class="login" :to="{ name: 'login' }">登录</router-link>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="手机" force-render>
              <a-form-item>
                <a-input size="large" type="text" placeholder="请输入手机号"
                         v-decorator="['mobile', {rules: [{ required: true, message: '请输入手机号！' },{ pattern: /^1[34578]\d{9}$/, message: '请输入正确的手机号' }], validateTrigger: 'change'}]">
                  <a-icon slot="prefix" type="mobile" :style="{ color: 'rgba(0,0,0,.25)' }"/>
                </a-input>
              </a-form-item>
              <a-row :gutter="16">
                <a-col class="gutter-row" :span="14">
                  <a-form-item>
                    <a-input size="large" type="text" placeholder="验证码"
                             v-decorator="['captcha', {rules: [{ required: true, message: '请输入验证码！' }], validateTrigger: 'blur'}]">
                      <a-icon slot="prefix" type="mail" :style="{ color: 'rgba(0,0,0,.25)' }"/>
                    </a-input>
                  </a-form-item>
                </a-col>
                <a-col class="gutter-row" :span="10">
                  <a-button
                    class="getCaptcha"
                    tabindex="-1"
                    :disabled="state.smsSendBtn"
                    @click.stop.prevent="getPhoneCaptcha"
                    v-text="!state.smsSendBtn && '获取验证码' || (state.time+' s')"
                  ></a-button>
                </a-col>
              </a-row>
              <a-form-item has-feedback>
                <a-input
                  placeholder="输入密码"
                  v-decorator="[ 'password', { rules: [ { required: true, message: '请输入密码', }, { validator: validateToNextPassword}]}]"
                  type="password"
                  size="large"
                >
                  <img class="img2" :src="mima" slot="prefix" alt=""/>
                </a-input>
              </a-form-item>
              <a-form-item has-feedback>
                <a-input
                  placeholder="请确认密码"
                  size="large"
                  v-decorator="[ 'confirm', { rules: [ { required: true, message: '两次输入的密码不匹配',},{ validator: compareToFirstPassword }]}]"
                  type="password" @blur="handleConfirmBlur"
                >
                  <img class="img2" :src="mima" slot="prefix" alt=""/>
                </a-input>
              </a-form-item>
              <a-button
                class="submit"
                size="large"
                type="primary"
                htmlType="submit"
                :loading="state.loginBtn"
                :disabled="state.loginBtn"
              >提交
              </a-button>
              <div class="user-login"> 放弃修改，直接
                <router-link class="login" :to="{ name: 'login' }">登录</router-link>
              </div>
            </a-tab-pane>
          </a-tabs>
        </a-form>

      </div>
    </div>
    <Footer></Footer>
  </div>

</template>

<script>
import user from '../../assets/img/55.png'
import mima from '../../assets/img/56.png'
import Header from '@/components/tools/Header.vue'
import Footer from '@/components/Footer.vue'
import axios from 'axios'
import md5 from '@/utils/MD5.js'

export default {
  name: 'forgetPassword',
  data () {
    return {
      user,
      md5,
      mima,
      customActiveKey: 'tab1',
      loginBtn: false,
      // login type: 0 email, 1 username, 2 telephone
      loginType: 0,
      isLoginError: false,
      requiredTwoStepCaptcha: false,
      stepCaptchaVisible: false,
      form: this.$form.createForm(this),
      state: {
        time: 180,
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        smsSendBtn: false
      },
      registerType: '1', // 1 默认是邮箱 2 按照手机
      value: 1// 教师还是学生选择
    }
  },
  mounted () {
    this.getHeight()
  },
  methods: {
    registerCodeCheck (key) {
      this.registerType = key
      console.log(this.registerType)
    },
    handleTabClick (key) {
      this.customActiveKey = key
    },
    handleUsernameOrEmail (rule, value, callback) {
      const { state } = this
      const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/
      if (regex.test(value)) {
        state.loginType = 0
      } else {
        state.loginType = 1
      }
      callback()
    },
    // 获取邮箱验证码
    getCaptcha (e) {
      e.preventDefault()
      const { form: { validateFields }, state, $message, $notification } = this
      validateFields(['email'], { force: true },
        (err, values) => {
          if (!err) {
            state.smsSendBtn = true
            const interval = window.setInterval(() => {
              // console.log('首次注册的信息',values)
              if (state.time-- <= 0) {
                state.time = 180
                state.smsSendBtn = false
                window.clearInterval(interval)
              }
            }, 1000)

            const hide = $message.loading('验证码发送中..', 0)
            const param = {
              email: values.email,
              client_id: this.$clientId,
              client_secret: this.$clientSecret,
              sendType: '3',
              time: (new Date()).valueOf()
            }

            const sign = md5.paramMd5(param, 'client_secret', this.$clientSecret)
            param.sign = sign
            const _this = this
            _this.$http.post(
              _this.$loginServer + 'auth/res/encrypt/sendEmailVerCode',
              param
            ).then(res => {
              console.log('验证码', res)
              if (res && res.data && res.data.resultData && res.data.resultCode == 0) {
                setTimeout(hide, 2500)
                $notification.success({
                  message: '提示',
                  description: '验证码获取成功,请您到注册的邮箱查看',
                  duration: 5
                })
              } else {
                setTimeout(hide, 500)
                $notification.error({
                  message: '提示',
                  description: res.data.resultMessage,
                  duration: 5
                })
              }
            }).catch(err => {
              setTimeout(hide, 1)
              clearInterval(interval)
              state.time = 180
              state.smsSendBtn = false
              // this.requestFailed(err)
            })
          }
        })
    },
    handleConfirmBlur (e) {
      const value = e.target.value
      this.confirmDirty = this.confirmDirty || !!value
    },
    validateToNextPassword (rule, value, callback) {
      const form = this.form
      if (value && this.confirmDirty) {
        form.validateFields(['confirm'], { force: true })
      }
      callback()
    },
    compareToFirstPassword (rule, value, callback) {
      const form = this.form
      if (value && value !== form.getFieldValue('password')) {
        callback('两次输入的密码不一致')
      } else {
        callback()
      }
    },
    handleSubmit (e) {
      // console.log('Received values of form: ', e)
      e.preventDefault()
      const _th = this
      const { form: { validateFields }, state, $message, $notification } = this
      this.form.validateFieldsAndScroll((err, values) => {
        // console.log('Received values of form7: ', state)
        // console.log('Received values of form6: ', validateFields)
        // console.log('Received values of form4: ', err)
        // console.log('Received values of form5: ', values)
        // console.log('Received values of form8: ', this.registerType)
        // console.log('Received values of form9: ', this.registerType == '1')
        if (_th.registerType == '1') {
          if (!err || !err.email && !err.confirm && !err.captcha) {
            // console.log('Received values of form3: ', values)
            const hide = $message.loading('修改密码中..', 0)
            const param = {
              email: values.email,
              password: values.password,
              confirmPassword: values.confirm,
              code: values.captcha,
              client_id: this.$clientId,
              client_secret: this.$clientSecret,
              time: (new Date()).valueOf()
            }
            const sign = md5.paramMd5(param, 'client_secret', this.$clientSecret)
            param.sign = sign
            this.$http.post(
              this.$loginServer + 'auth/res/encrypt/forgetByEmailCodeResetUserPwd', param)
              .then(res => {
              //  console.log(res)
                if (res && res.data && res.data.resultCode == 0) {
                  setTimeout(hide, 100)
                  $notification.success({
                    message: '提示',
                    description: '修改密码成功'
                  })
                  this.$router.push({ name: 'login' })
                } else {
                  setTimeout(hide, 100)
                  $notification.error({
                    message: '提示',
                    description: res.data.resultMessage,
                    duration: 5
                  })
                }
              })
          }
        } else {
          if (!err || !err.phone && !err.confirm && !err.captcha) {
            // console.log('Received values of form3: ', values)
            const hide = $message.loading('修改密码中..', 0)
            const param = {
              password: values.password,
              confirmPassword: values.confirm,
              code: values.captcha,
              client_id: this.$clientId,
              client_secret: this.$clientSecret,
              time: (new Date()).valueOf()
            }
            const _this = this
            param.phone = values.mobile
            const sign = md5.paramMd5(param, 'client_secret', this.$clientSecret)
            param.sign = sign
            this.$http.post(
              _this.$loginServer + 'auth/res/encrypt/forgetByPhoneCodeResetUserPwd',
              param
            ).then(res => {
              // console.log(res)
              if (res && res.data && res.data.resultCode == 0) {
                setTimeout(hide, 100)
                $notification.success({
                  message: '提示',
                  description: '修改密码成功'
                })
                this.$router.push({ name: 'login' })
              } else {
                setTimeout(hide, 100)
                $notification.error({
                  message: '提示',
                  description: res.data.resultMessage,
                  duration: 5
                })
              }
            })
          }
        }
      })
    },
    getPhoneCaptcha (e) {
      e.preventDefault()
      const { form: { validateFields }, state, $message, $notification } = this
      validateFields(['mobile'], { force: true },
        (err, values) => {
          if (!err) {
            state.smsSendBtn = true
            const interval = window.setInterval(() => {
            //  console.log('修改密码手机号信息', values)
              if (state.time-- <= 0) {
                state.time = 180
                state.smsSendBtn = false
                window.clearInterval(interval)
              }
            }, 1000)
            const hide = $message.loading('验证码发送中..', 0)
            const emailCodeParam = {
              client_id: this.$clientId,
              client_secret: this.$clientSecret,
              phone: values.mobile,
              sendType: '3',
              time: (new Date()).valueOf()
            }
            const sign = md5.paramMd5(emailCodeParam, 'client_secret', this.$clientSecret)
            emailCodeParam.sign = sign
            // console.log('修改密码手机号信息-emailCodeParam', emailCodeParam)
            const _this = this
            this.$http.post(
              _this.$loginServer + 'auth/res/encrypt/sendPhoneVerCode',
              emailCodeParam
            ).then(res => {
            //  console.log(res)
              if (res && res.data && res.data.resultData && res.data.resultCode == 0) {
                setTimeout(hide, 2500)
                $notification.success({
                  message: '提示',
                  description: '验证码获取成功,请您手机查看',
                  duration: 5
                })
              } else {
                setTimeout(hide, 500)
                $notification.error({
                  message: '提示',
                  description: res.data.resultMessage,
                  duration: 5
                })
              }
            }).catch(err => {
              setTimeout(hide, 1)
              clearInterval(interval)
              state.time = 180
              state.smsSendBtn = false
              // this.requestFailed(err)
            })
          }
        })
    },
    getHeight () {
      const windowHeight = window.innerHeight - 60 - 293
      document.getElementById('content').style.height = windowHeight + 'px'
      if (windowHeight - 480 > 0) {
        document.getElementById('login').style.marginTop = (windowHeight - 480) / 2 + 'px'
      } else {
        document.getElementById('login').style.marginTop = 10 + 'px'
      }
      if (windowHeight - 370 > 0) {
        document.getElementById('login_img').style.marginTop = (windowHeight - 370) / 2 + 'px'
      } else {
        document.getElementById('login_img').style.marginTop = 65 + 'px'
      }
      document.getElementById('login_img').style.marginLeft = (windowHeight) / 1.5 + 'px'
    }
  },
  components: {
    Header,
    Footer
  }
}
</script>

<style lang="less" scoped>
.ant-layout-header, .ant-layout-content, .ant-layout-footer {
  margin: 0;
  padding: 0;
  background: #FFF;
}

.ant-layout-header {
  height: 64px;
  border-bottom: 1px solid rgba(61, 61, 61, .15);
  box-sizing: border-box;
}

.register {
  // max-width: 1920px;
  min-height: 500px;
  //min-height: 800px;
  // background-image: url('../../assets/img/loginBgc.png');
  background-size: cover;
  background-image: url("../../assets/img/login-bg-imp.png");
  background-size: 100% 100%;
  height: auto;
  overflow: auto;

  .login-img_bg {
    width: 119px;
    height: 370px;
    position: absolute;
    background-image: url('../../assets/img/login-bg-text.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: 160px;
    margin-left: 480px;
  }

  .login-form {
    width: 400px;
    height: 480px;
    background-image: url("../../assets/img/bg_login_form.png");
    background-size: 100% 100%;
    padding: 30px 50px;
    position: absolute;
    margin-top: 120px;
    right: 0px;
    //background: #fff;

    .title {
      font-size: 24px;
      font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
      font-weight: 500;
      color: #000000;
      line-height: 22px;
      text-align: center;
      //margin-bottom: 35px;
    }

    .img1 {
      width: 17px;
      height: 14px;
    }

    .img2 {
      width: 16px;
      height: 16px;
    }
  }

  .emailForm {
    margin-top: 20px;
  }

  .roleSelect {
    height: 40px;
    margin-top: 10px;
  }

  .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 50px;
  }

  /* 提交注册按钮 */

  .submit {
    width: 100%;
    height: 36px;
    font-size: 16px;
  }

  .user-login {
    text-align: center;
    z-index: 2;
    padding-top: 15px;
    padding-bottom: 20px;

    .login {
      color: #DB5647;
    }
  }
}

.getCaptcha {
  width: 115px;
  height: 40px;
  background: #DB5647;
  color: #fff;
}

/deep/ .ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 35px;
}

/deep/ .ant-tabs-nav .ant-tabs-tab {
  font-size: 18px;
  margin: 0px;
  padding: 12px 30px;
}

/deep/ .ant-tabs-nav-container {
  text-align: center;
}

/deep/ .ant-tabs-ink-bar {
  position: absolute;
  bottom: 1px;
  left: 22px;
  z-index: 1;
  box-sizing: border-box;
  width: 50px !important;
  height: 2px;
  //background-color: #db5647;
  transform-origin: 0 0;
}
</style>
